




















































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import OfferService from '@/services/OfferService';
import CoordinatesService from '@/services/CoordinatesService';
import { disciplines, focus } from '@/components/Offer/constants/disciplines.constants';
import { practice_form } from '@/components/Offer/constants/practiceform.constants';
import { offer_state } from '@/components/Offer/constants/offer-state.constant';
import { public_transports } from '@/components/Offer/constants/public-transports.constants';
import { supply_area, supply_mission, admission_restriction } from '@/components/Offer/constants/supply.constants';
import { OfferDto } from './dtos/offer.dto';

@Component
export default class EditOffer extends Vue {
  private loading = false;
  private offer = new OfferDto();
  private wishedSellDateMenu = false;
  private snackbar = false;
  
  // load enums
  private disciplines = disciplines;
  private focus = focus;
  private supply_area = supply_area;
  private supply_mission = supply_mission;
  private admission_restriction = admission_restriction;
  private practice_form = practice_form;
  private public_transports = public_transports;
  private offer_state = offer_state;

  private images: { src: string }[] = []
  private upload = [];
  
  get showImages() {
    return this.images.length > 0 || this.upload.length > 0;
  }
  get previewImages()
  {
    let imgList: any[] = [];
    this.images.forEach(val => imgList.push(Object.assign({}, val)));
    this.upload.forEach((file) => {
      imgList.push({ src: URL.createObjectURL(file)})
    });
    return imgList;
  }

  async validateAddress(): Promise<boolean> {
    const address = await CoordinatesService.findAddress(this.offer.property.address);
    if(address) {
      const { street, zip, city } = this.offer.property.address;
      if(address.street != street || address.city != city || address.zip != zip) {
        this.offer.property.address.street = address.street;
        this.offer.property.address.city = address.city;
        this.offer.property.address.zip = address.zip;
        alert('Addresse ist geändert worden, bitte überprüfen.')
        return false;
      }

      this.offer.property.address.lon = address.lon;
      this.offer.property.address.lat = address.lat;
      return true;
    }
    alert('Die Adresse ist unbekannt!');
    return false;
  }

  async upsertOffer(): Promise<void> {

    const addressValid = await this.validateAddress();

    if(!addressValid) {
      return;
    }


    this.loading = true;
    const newOffer = await OfferService.upsertOffer(this.offer);
    if(newOffer.status) {
      alert ('saving not successful: ' + newOffer.message);
      return;
    }
    this.snackbar = true;

    //save images
    if(this.upload.length) {
      await OfferService.saveImages(newOffer.id, this.upload);
      this.upload.forEach((file) => {
        this.images.push({ src: URL.createObjectURL(file)})
      });
      this.upload = [];
    }
    this.loading = false;
    // reload page with new offer
    this.$router.push(`/edit-offer/${newOffer.id}`);
  }

  async mounted() {
    if(!this.$route.params.id) {
      return;
    }
    this.offer = await OfferService.getOffer(this.$route.params.id);
    const images = this.offer.images.map(src => {
      return { src };
    });
    this.images = images;
  }
}
